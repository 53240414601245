import * as React from 'react';
import './blog-post-cards.component.css';
import CardComponent from '../card/card.component';
import withRouter from '../../withRouter';

class BlogPostCardsComponent extends React.Component<any, any> {
  public constructor(props: any, state: any) {
    super(props, state);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    fetch('startpage_posts.json')
      .then((response: any) => response.json())
      .then((text: any) => {
        this.setState({
          data: text
        });
      });
  }
  render() {
    return (
      <>
        {this.state.data.map((post: any, key: any) => {
          return (
            <CardComponent
              onClick={() => {
                this.props.router.navigate('/blog/post/' + post.id);
              }}
              key={key}
              title={post.title}
              date={post.date}
              short_desc={post.short_desc}
            />
          );
        })}
      </>
    );
  }
}

export default withRouter(BlogPostCardsComponent);
