import * as React from 'react';
import { Typography, CardContent, Card, Grow } from '@mui/material';
import './imprint.page.css';
import folivora2 from '../../images/folivora2.png';

export interface ImprintPageProps {}

export default class ImprintPage extends React.Component<
  ImprintPageProps,
  any
> {
  render() {
    return (
      <div className="imprintPage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="firstContentBlock">
            <CardContent className="centerCardContent">
              <h1 className="lightSectionTitle">Imprint / Contact</h1>

              <Typography component="p" color="inherit">
                <strong>folivora.AI GmbH</strong>
                <br />
                Fasanenstraße 2
                <br />
                82008 Unterhaching
                <br />
                <br />
                <br />
                <strong>CEO and authorized representative</strong>
                <br />
                Andreas Hegenberg
                <br />
                <br />
                <strong>Contact</strong>
                <br />
                Phone: +49 1515 4832755
                <br />
                E-Mail: <b>andreas@folivora.ai</b>
              
                <span>
                {' '}( <b>Note</b>: For standard support (questions/feature requests/bug
                  reports) please use{' '}
                  <a href="https://community.folivora.ai">
                    community.folivora.ai
                  </a>
                  . For issues regarding your license / payment contact me vie e-mail. )
                </span>
                <br />
                <br />
                <strong>Trade register number and place of registration</strong>
                <br />
                Registry Office: Amtsgericht München
                <br />
                Registry number: HRB 239259
                <br />
                VAT-ID: DE 317011275
                <br />
                Registered office: Munich, Germany
                <br />
                <br />
                <strong>
                  Responsible for journalistic content (according to § 55 Abs. 2
                  RStV)
                </strong>
                <br />
                Andreas Hegenberg
                <br />
                <br />
                <strong>Privacy Policy</strong>
                <br />
                <a href="https://folivora.ai/privacy">
                  https://folivora.ai/privacy
                </a>
                <br />
                <br />
                <br />
                Liability for contents
                <br />
                Although the content of our pages has been created with great
                care the folivora.AI GmbH cannot guarantee the accuracy,
                completeness and timeliness of the content. According to current
                legal regulations we are responsible for the content of these
                pages. However, we are not obligated to control transferred or
                saved information or search for circumstances indicating illegal
                operation. The responsibility to delete or block the use of
                information according to general law remains applicable. The
                company can be held liable no sooner than a certain legal
                infringement has come to its attention. After legal
                infringements have come to our attention we will remove the
                relevant content immediately.
                <br /> <br />
                Liability for links
                <br />
                Our website contains links (referrals) to external websites,
                whereby we have no influence on the content of these third-party
                websites. For this reason we cannot be held liable for any
                external contents. The content of linked pages is the sole
                responsibility of the relevant provider. At the time the link to
                the referred pages was made, those pages were checked for
                possible illegal content. No illegal content was found at that
                time. A continuous content check of the linked pages is not
                reasonable without any tangible indication. If legal
                infringements become known, affected links will be removed
                immediately.
                <br /> <br />
                Copyright
                <br />
                The contents created by the website provider of this page
                underlie the German copyright. The duplication, change,
                dispersion and any other kind of use outside the limits of the
                copyright require the written consent of the relevant author. If
                the contents of this page have not been created by the provider,
                the copyrights of third parties must be acknowledged. Third
                party contents will be marked as such. Should a copy right
                infringement come to your attention, we would ask you to inform
                us of this. After relevant infringements have come to our
                attention we will remove the relevant content immediately.
              </Typography>
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}
