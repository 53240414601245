import * as React from "react";

import {
  Card,
  CardContent,
  Grow,
  Typography,
  CardActions,
  Button,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import folivora2 from "../../images/folivora2.png";
import PurchaseDialog from "./purchase-dialog/purchase-dialog";
import setappLogo from "../../images/setapp.svg";
import { ExpandMore } from "@mui/icons-material";

import "./purchase.page.css";
import btticon from "../../images/btticon.png";
declare var Paddle: any;
class PurchasePage extends React.Component<AnyProps, AnyProps> {
  private standardLicenseProductNumber = 527825;
  private lifetimeLicenseProductNumber = 527823;

  state = {
    showPurchaseDialog: false,
    currentlyAllowedProductType: undefined,
    currentlyAllowedQuantity: true,
    purchaseDialogTitle: "Purchase License(s)",
    didLoadPaddleScript: false,
    blackFriday: false,
    coupon: undefined,
    safari: false,
  };

  componentDidMount() {
    this.setState({
      safari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    });
  }

  private loadPaddleScript() {
    if (this.state.didLoadPaddleScript !== true) {
      var s = document.createElement("script");
      s.src = "https://cdn.paddle.com/paddle/paddle.js";
      document.head.appendChild(s);
      this.setState({ didLoadPaddleScript: true });
    }
  }

  private onPurchase(productType: number, allowQuantity?: boolean) {
    this.loadPaddleScript();

    this.setState({
      currentlyAllowedQuantity: allowQuantity,
      currentlyAllowedProductType: productType,
      showPurchaseDialog: true,
    });

    if (productType === this.standardLicenseProductNumber) {
      this.setState({ purchaseDialogTitle: "Purchase Standard License(s)" });
    }
    if (productType === this.lifetimeLicenseProductNumber) {
      this.setState({ purchaseDialogTitle: "Purchase Lifetime License(s)" });
    }
  }

  private openSetapp() {
    window.open(
      "https://go.setapp.com/stp202?refAppID=295&stc=bettertouchtool_main_banner&utm_campaign=bettertouchtool_main_banner&utm_content=banner&utm_medium=vendor_program&utm_source=bettertouchtool"
    );
  }

  private handlePurchaseDialogueNext = (
    continueToCheckout: boolean,
    licenseOwnerName: String,
    selectedQuantity?: number,
    selectedEmailAddress?: String,
    useDifferentLicenseOwner?: boolean,
    differentLicenseOwnerEmail?: String

  ) => {
    Paddle.Setup({
      vendor: 30842,
      debug: false,
    });
    this.setState({ showPurchaseDialog: false });
    console.log("quant", selectedQuantity);
    if (continueToCheckout === true) {
      if (this.state.coupon !== undefined) {
        Paddle.Checkout.open({
          product: this.state.currentlyAllowedProductType,
          passthrough: useDifferentLicenseOwner === true ? `${licenseOwnerName};;;${differentLicenseOwnerEmail}` : licenseOwnerName,
          allowQuantity: true || this.state.currentlyAllowedQuantity,
          quantity: selectedQuantity,
          coupon: this.state.coupon,
          marketingConsent: "0",
          email: selectedEmailAddress,
        });
      } else {
        Paddle.Checkout.open({
          product: this.state.currentlyAllowedProductType,
          passthrough: useDifferentLicenseOwner === true ? `${licenseOwnerName};;;${differentLicenseOwnerEmail}` : licenseOwnerName,
          allowQuantity: true || this.state.currentlyAllowedQuantity,
          quantity: selectedQuantity,
          marketingConsent: "0",
          email: selectedEmailAddress,
        });
      }
    }
  };

  render() {
    // const content = this.props.content;
    const { showPurchaseDialog } = this.state;
    return (
      <div className="purchasePage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />

        {showPurchaseDialog && (
          <PurchaseDialog
            purchaseDialogTitle={this.state.purchaseDialogTitle}
            onPurchaseDialogNext={this.handlePurchaseDialogueNext}
          />
        )}

        {this.state.blackFriday === true ? (
          <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
            <Card className="blackFriday">
              <CardContent style={{ minHeight: "50px", textAlign: "center" }}>
                <Typography color="inherit" component="h2">
                  Black Friday! 25% off on all BetterTouchTool licenses. Coupon
                  code: <b>BTT_BLACK_FRIDAY_2023</b>
                </Typography>
              </CardContent>
            </Card>
          </Grow>
        ) : (
          ""
        )}

        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card
            className="firstContentBlock"
            onClick={() => {
              this.onPurchase(this.standardLicenseProductNumber, false);
            }}
          >
            <CardContent style={{ minHeight: "200px", textAlign: "center" }}>
              <Typography color="inherit" variant="h5" component="h2">
                BetterTouchTool Standard License
              </Typography>
              <Typography color="inherit" component="p">
                A standard license will receive updates for 2 years. After these
                two years you can keep using the last version you received
                during that timeframe and keep using it forever.
              </Typography>
              <img src={btticon} width="100px" style={{ marginTop: "10px" }} />
              <Typography color="inherit" component="p">
                <strong>$12</strong> but depends on country &amp; currency &amp;
                taxes. Follow the purchase dialog to see the exact price for
                your region.
              </Typography>
            </CardContent>
            <CardActions style={{ display: "flex", flexDirection: "column" }}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                style={{ margin: "0 auto" }}
              >
                Buy Standard License
                <span style={{ fontSize: 10 }}>&nbsp;(via Paddle.com)</span>
              </Button>
              {this.state.safari !== true && (
                <Typography
                  color="inherit"
                  variant="subtitle2"
                  component="p"
                  style={{ margin: '15px' }}
                >
                  Apple Pay payment is only available in Safari.
                </Typography>
              )}
            </CardActions>
          </Card>
        </Grow>

        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card
            className="secondContentBlock"
            onClick={() => {
              this.onPurchase(this.lifetimeLicenseProductNumber, true);
            }}
          >
            <CardContent style={{ minHeight: "200px", textAlign: "center", display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center',  justifyContent: 'space-evenly' }}>
              <Typography color="inherit" variant="h5" component="h2">
                BetterTouchTool Lifetime License
              </Typography>
              <Typography color="inherit" component="p">
                A lifetime license will receive all updates while
                BetterTouchTool exists. Additionally the license will be valid
                for all macOS apps (if any) which I release during the two years
                following the purchase.
              </Typography>
              <img src={btticon} width="100px" style={{ marginTop: "10px" }} />
              <Typography color="inherit" component="p">
                <strong>$24</strong> but depends on country &amp; currency &amp;
                taxes. Follow the purchase dialog to see the exact price for
                your region.
              </Typography>
            </CardContent>
            <CardActions
              style={{ display: "flex", flexDirection: "column", height: 70 }}
            >
              <Button
                color="primary"
                variant="contained"
                size="large"
                style={{ margin: "0 auto" }}
              >
                Buy Lifetime License{" "}
                <span style={{ fontSize: 10 }}>&nbsp;(via Paddle.com)</span>
              </Button>
              {this.state.safari !== true && (
                <Typography
                  color="inherit"
                  variant="subtitle2"
                  component="p"
                  style={{ margin: '15px' }}
                >
                  Apple Pay payment is only available in Safari.
                </Typography>
              )}
            </CardActions>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card
            className="setappBox"
            onClick={() => {
              this.openSetapp();
            }}
          >
            <CardContent style={{ minHeight: "240px", textAlign: "center" }}>
              <Typography color="inherit" variant="h5"  component="h2">
                Setapp subscription
              </Typography>
              <Typography color="inherit" component="p">
                BetterTouchTool is also available on Setapp (a cool
                subscription service for Mac apps that gives you access to tons
                of apps for a monthly fee)
                <br />
                <br />
                <p className="shorterSetappDescription">
                  This is a great option if you'd also like to use some of the
                  other apps available on Setapp.
                </p>
              </Typography>

              <img src={setappLogo} style={{ marginTop: "40px" }} />
            </CardContent>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="thirdContentBlock">
            <CardContent>
              <Typography component="h2" color="inherit">
                License Terms / Terms of Service
              </Typography>
              <Typography component="p" color="inherit">
                Valid for licenses purchased on or after 15.February.2018
              </Typography>
              <Accordion
                className="licenseInfoPanel"
                style={{ marginTop: "10px" }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography color="inherit" className="expandableTitle">
                    Differences between Standard and Lifetime licenses
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    <strong>Standard licenses</strong> receive free updates for
                    2 years. Afterwards you can continue to use the last version
                    you received within this timeframe.
                    <br />
                    <br /> <strong>Lifetime licenses</strong> receive free
                    updates as long as BetterTouchTool exists. Also they are
                    valid for all other Mac apps that I release during the 2
                    years following the purchase (if any).
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Multi Computer Usage and Corporate Usage
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    For each license type (Standard & Lifetime) you can choose
                    between using it{" "}
                    <strong>either per-user OR per-seat</strong>. This is not a
                    formal decision, you can just use it the way that fits your
                    use-case!
                    <br />
                    <br />
                    Typically private individuals would choose to use it
                    per-user which allows to use the license on all computers
                    where the user who purchased the license (or whom it was
                    purchased for) is the main user of the machine. Additionally
                    I allow to use it on 1 shared family computer.
                    <br />
                    <br /> Companies would choose the per-seat usage model,
                    which allows the license to be used by different users at
                    one machine (you can install it on multiple machines but
                    only use it on one at a time).
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Updates & Upgrades
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    Standard licenses include all BetterTouchTool updates for at
                    least 2 years for free. After those two years you will be
                    allowed to continue using the last version you received.
                    <br />
                    <br />
                    Lifetime licenses include all BetterTouchTool updates as
                    long as BetterTouchTool exists.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Expiry
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    See previous section "Updates & Upgrades"
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Using a privately bought license at work
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    You are allowed to use your personal license at work – as
                    long as you are the main user of the computer you want to
                    use it on.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Educational Institutions
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    If you plan to purchase <strong>multiple</strong>{" "}
                    BetterTouchTool licenses for educational uses, please
                    contact me via andreas@folivora.ai. There are <strong>no discounts</strong> for individual students or other members of the school/university, sorry!
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Reselling
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    You are allowed to purchase licenses for other people upon
                    request, but you are not allowed to create a shop that
                    offers my tools.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    License Delivery
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    After your payment has completed you will receive an e-mail
                    that contains your license file. You can download &amp;
                    double-click the license file in order to activate
                    BetterTouchTool. The license e-mail also contains an
                    activation link that you can use instead in order to
                    activate BetterTouchTool.
                    <br />
                    In case you can't find the e-mail after your payment has
                    completed, first make sure to check your spam folder. If
                    that doesn't help, please contact me via{" "}
                    <strong>andreas@folivora.ai</strong>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Support for upcoming macOS Versions
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    There is no guarantee that BetterTouchTool will be
                    compatible with upcoming macOS versions. This is because I
                    do not know how the operating system will change and if it
                    will still be possible to use the APIs BetterTouchTool needs
                    to work.
                    <br />
                    <br />I promise to do my best to have BTT working with new
                    macOS versions for as long as possible.
                    <br />
                    <br />
                    At the moment (October 2023) BetterTouchTool works on macOS 10.15.x, macOS 11, macOS 12, macOS 13 and also the new macOS 14 Sonoma (including Apple Silicon / M1 & M2 & M3).Not all features are available for
                    all versions though. If you are still using macOS 10.13-10.15 
                    you can download the appropriate BTT version (version 4.315) from the
                    downloads page.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Support for old macOS versions
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    BetterTouchTool doesn't keep macOS compatibility with
                    specific macOS versions forever. This has various reasons.
                    For example in older macOS versions designing user
                    interfaces is much harder because many of the new features
                    and APIs are not available. In general BetterTouchTool tries
                    to stay compatible with the last 3 major macOS versions. The
                    old BTT versions will however keep working on older macOS
                    versions and are available for download in the downloads
                    section.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    BTT Remote Pro
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    The BetterTouchTool license also allows you to use all
                    features of the iOS companion app BTT Remote. If you connect
                    BTT Remote to a licensed BetterTouchTool, it will
                    automatically switch to the PRO mode.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <br />
              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    License Prices
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    To see the exact license prices for your currency and
                    country (including the value added tax), you need to click
                    the appropriate buy button and follow the dialog. The prices
                    shown will include the appropriate value added tax for the
                    selected country.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Merchant of Record / Contractual Partner Paddle.com &amp;
                    Setapp.com
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    The product offers displayed on folivora.ai constitute an
                    invitation to submit a contract offer to paddle.com or
                    setapp.com as described here:
                    <br />
                    <br />
                    Our order process for standard or lifetime licenses is
                    conducted by our online reseller Paddle.com. Paddle.com is
                    the Merchant of Record for all our standard or lifetime
                    license orders. Paddle provides all customer service
                    inquiries and handles returns for these orders. In case
                    there are any contradictions between the paddle.com{" "}
                    <a
                      style={{
                        color: "white",
                        textDecoration: "underline",
                      }}
                      href={
                        "https://help.paddle.com/hc/en-us/articles/" +
                        "115002235349-Terms-and-Conditions-Privacy-Policy"
                      }
                    >
                      Terms and Conditions
                    </a>{" "}
                    and the terms and conditions listed here, the paddle.com
                    Terms and conditions are deemed to take precedence.
                    <br />
                    <br />
                    When opting for the Setapp subscription model, the exlusive
                    vendor, Merchant of Record &amp; contractual partner is
                    Setapp Limited, NSC Campus, Mahon, Cork, T12 XY2N, Irland.
                    In case there are any contradictions between the Setapp{" "}
                    <a
                      style={{
                        color: "white",
                        textDecoration: "underline",
                      }}
                      href="https://setapp.com/terms-of-use"
                    >
                      Terms of Use
                    </a>{" "}
                    and the terms and conditions listed here, the Setapp Terms
                    of Use are deemed to take precedence.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Refunds / Order Cancellation
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    <strong>
                      {" "}
                      Refunds are available within 14 days of purchase
                    </strong>
                    , you do not need to provide a reason if you decide to
                    request a refund.
                    <br />
                    To request a refund, just send an e-mail to{" "}
                    <strong>andreas@folivora.ai</strong>. Alternatively you can
                    send us a letter (for the address see the imprint).Your
                    refund will be processed if you contact us within 14 days
                    after the purchase. You can use the following template to
                    request a refund:
                    <br />
                    <hr />
                    Subject: "BetterTouchTool Refund"
                    <br />
                    Your Name
                    <br />
                    Your Order E-Mail
                    <br />
                    Text: "Please refund my BetterTouchTool order{" "}
                    <strong>insert order id here</strong>"<br />
                    <br />
                    <br />
                    <br />
                    <hr />
                    In case you can not provide some of the requested
                    information, we will look it up for you.
                    <br />
                    <strong>What happens after you request a refund?</strong>
                    <br />
                    As soon as we receive your refund request we will tell our
                    reseller to send back your money. This will happen as soon
                    as possible, at latest 14 days after your request has been
                    received. Also your license will be revoked and will stop
                    working automatically after 14 days.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Privacy Policy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    You can find our privacy policy here:
                    <a
                      style={{ color: "yellow" }}
                      href="https://folivora.ai/privacy"
                    >
                      https://folivora.ai/privacy
                    </a>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Disclaimer
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    Your use of the Service is at your sole risk. The Service is
                    provided on an "AS IS" and "AS AVAILABLE" basis. The Service
                    is provided without warranties of any kind, whether express
                    or implied, including, but not limited to, implied
                    warranties of merchantability, fitness for a particular
                    purpose, non-infringement or course of performance.
                    <br />
                    folivora.AI GmbH its subsidiaries, affiliates, and its
                    licensors do not warrant that a) the Service will function
                    uninterrupted, secure or available at any particular time or
                    location; b) any errors or defects will be corrected; c) the
                    Service is free of viruses or other harmful components; or
                    d) the results of using the Service will meet your
                    requirements.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Terms of Service Changes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    We reserve the right, at our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    we will try to provide at least 30 days notice prior to any
                    new terms taking effect. What constitutes a material change
                    will be determined at our sole discretion.
                    <br />
                    By continuing to access or use our Service after those
                    revisions become effective, you agree to be bound by the
                    revised terms. If you do not agree to the new terms, please
                    stop using the Service.
                    <br />
                    For BetterTouchTool and BetterSnapTool licenses the license
                    terms of the date of purchase will stay binding for both you
                    (the customer) and us.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Place of jurisdiction
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    The place of jurisdiction for any legal matters arising from
                    the commercial relationship shall be Munich, Germany. This
                    shall be true if the parties to the contract/relationship
                    are businesspeople, legal entities under public law or or an
                    institutional fund governed by public law, or at least one
                    of the parties to the contract does not have its general
                    place of jurisdiction in the Federal Republic of Germany.
                    The same condition applies to situations in which the place
                    of residence or the usual place of residence is not known at
                    the time of commencement of proceedings. We shall however be
                    entitled to file lawsuits at the Customer's general place of
                    jurisdiction. The place of performance for all duties
                    arising from this contract shall be Munich, Germany.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="licenseInfoPanel">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="expandableTitle" color="inherit">
                    Questions?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="inherit">
                    In case there are still any questions left, please contact
                    me via andreas@folivora.ai
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}

export default PurchasePage;
